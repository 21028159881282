import dayjs from "dayjs"
import React from "react"
import { twMerge } from "tailwind-merge"

import Badge from "../../components/shared/Badge"
import ExpandableText from "../../components/shared/ExpandableText"
import Typography from "../../components/shared/Typography"

import ReviewStars from "./ReviewStars"

const Review = ({ review, practice, starColor, className, showEndorsements = false, showReplies = false }) => {
  const reviewText = review.review?.trim()
  const isAnonymous = review.isAnonymous
  const name = review.client?.firstName || review.user?.firstName || review.unverifiedName
  const lastName = review.client?.lastName || review.user?.lastName
  const firstName = name && name.split(" ")[0]
  const lastInitial = lastName ? `${lastName[0]}.`.toUpperCase() : ""
  const practitionerName = practice.user.name
  const rating = Math.round(review.rating)

  return (
    <div className={twMerge("flex flex-col gap-4 break-words p-0", className)}>
      {reviewText && <ExpandableText text={reviewText} maxLength={200} quotes={true} className="leading-7" />}
      {showEndorsements && review.endorsedSkills.length > 0 && (
        <div className="flex gap-2">
          {review.endorsedSkills.slice(0, 3).map((skill, index) => (
            <React.Fragment key={`${review.id}-${skill}`}>
              <div key={skill} className="font-bold">
                {skill}
              </div>
              {index < Math.min(review.endorsedSkills.length - 1, 2) && <div>|</div>}
            </React.Fragment>
          ))}
        </div>
      )}
      <div>
        <Typography variant="small" as="div" className="mb-2">
          {isAnonymous ? "Anonymous" : `${firstName} ${lastInitial}`} • {dayjs(review.createdAt).format("MMMM D, YYYY")}
        </Typography>
        <ReviewStars rating={rating} starColor={starColor} />
      </div>
      {review.timesBooked > 1 && (
        <Badge type="info" className="w-fit border bg-transparent">
          Booked {review.timesBooked} times
        </Badge>
      )}
      {showReplies && review.reviewReply && (
        <div className="flex gap-4">
          <div className="w-1 flex-none rounded bg-gray"></div>
          <div>
            <div className="text-sm font-bold">Reply from {practitionerName}</div>
            <div>&ldquo;{review.reviewReply.message}&rdquo;</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Review
